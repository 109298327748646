import _ from 'lodash'
import moment from 'moment'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonState } from '../service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import { findAll, findByPk, createItem, updateItem, deleteItem, addNewItem, changeDeletionMark, resetState } from '../service/actions'
import axios from 'axios'

import BasicObject from '@/dto/Disposition.json'
const apiUrl = 'disposition'

export const state = Object.assign({}, _.cloneDeep(commonState))

export const mutations = { ...commonMutations }

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload, itemHundler)
  },

  async create(context: ActionContext<IState, any>, payload: any) {
    return createItem(context, apiUrl, payload)
  },

  async update(context: ActionContext<IState, any>, payload: any) {
    return updateItem(context, apiUrl, payload)
  },

  async delete(context: ActionContext<IState, any>, payload: any) {
    return deleteItem(context, apiUrl, payload)
  },

  async changeDeletionMark(context: ActionContext<IState, any>, payload: any) {
    return changeDeletionMark(context, apiUrl, payload)
  },

  async addNew(context: ActionContext<IState, any>, payload: any) {
    return addNewItem(context, { basicObject: BasicObject }, payload)
  },

  async getSelectFields() {
    return [{ key: 'presentation', label: 'table.number', sortable: true }]
  },

  resetState(context: ActionContext<IState, any>) {
    resetState(context)
  },

  async getDispositionByTicket({ commit }: ActionContext<IState, any>, payload: any): Promise<any> {
    try {
      const response = await axios.get('/disposition_by_ticket', payload)
      return response.data
    } catch (err) {
      console.error(err)
    }
  },

  async getNumberOfWeighted({ dispatch }: ActionContext<IState, any>, payload: any): Promise<any> {
    try {
      const response = await axios.get(`/get_number_of_weighted/${payload.dispositionId}`, payload)
      return response.data
    } catch (err) {
      console.error(err)
    }
  },

  async sendExternalUserWeight({ commit }: ActionContext<IState, any>, payload: any): Promise<any> {
    try {
      const response = await axios.get('/disposition_send_weight_external_user', payload)
      return response.data
    } catch (err) {
      console.error(err)
    }
  },

  async getSubordination(context: ActionContext<IState, any>, payload: any): Promise<any> {
    const response = await axios.get(`/dispositions/subordination/${payload.objectId}`)
    return response.data
  },
  async getDispositionDriverTicketData({ commit }: ActionContext<IState, any>, payload: any): Promise<any> {
    try {
      const response = await axios.get(`/driver_tickets/disposition/${payload.params.dispositionId}`, payload)
      return response.data
    } catch (err) {
      console.error(err)
    }
  },
  async updateDispositionDriverTicketData(context: ActionContext<IState, any>, payload: any) {
    try {
      const response = await axios.put(`/dispositions/write_driver_tickets`, payload)
      return response.data
    } catch (err) {
      console.error(err)
    }
  },
  async createPhoto(context: ActionContext<IState, any>, payload: any) {
    //payload       {workingPlaceId or cameraId}
    try {
      return await axios.post(`/disposition/photo`, payload)
    } catch (err) {
      console.error(err)
      return {status: 400, data:null}
    }
  },
  async getOnePhoto(context: ActionContext<IState, any>, payload: any) {
    try {
      return await axios.get(`/disposition/photo/${payload.photoId}`, payload)
    } catch (err) {
      console.error(err)
      return {status: 400, data: null}
    }
  },
  async listPhoto(context: ActionContext<IState, any>, payload: any) {
    try {
      return await axios.get(`/disposition/photos/${payload.dispositionId}`, payload)
    } catch (err) {
      console.error(err)
      return {status: 400, data:null}
    }
  },

  async getScalesForCheck({ commit }: ActionContext<IState, any>, payload: any): Promise<any> {
    console.log('payload.params = ', payload.params)
    try {
      const response = await axios.get(`/dispositions/scales_for_check/${payload.params.id}`, payload)
      return response.data
    } catch (err) {
      console.error(err)
    }
  },

  async analizedWeighingsCheckBeforeBunkerStart(context: ActionContext<IState, any>, payload: any) {
    try {
      const response = await axios.get(`dispositions/analized_weighings_check_before_bunker_start`, payload)
      return response.data
    } catch (err) {
      console.error(err)
    }
  },
}
async function itemHundler(itemData: any) {
  itemData.object.createdAt = moment(itemData.object.createdAt).format('DD.MM.YYYY HH:mm:ss')
  itemData.object.barCode = null
  itemData.object.driverTicket = null
  itemData.object.entryTicket = null

  try {
    const response = await axios.get(`/driver_tickets/disposition/${itemData.object.id}`, {})
    if (response && response.data.length > 0) {
      itemData.object.barCode = response.data[0].barcode
      itemData.object.driverTicket = response.data[0].driverTicket
      itemData.object.entryTicket = response.data[0].entryTicket
    }
  } catch (err) {
    console.error(err)
  }
  itemData.object.readOnlyBarcode = itemData.object.barCode !== null
  itemData.object.readOnlyDriverTicket = itemData.object.driverTicket !== null
  itemData.object.readOnlyEntryTicket = itemData.object.entryTicket !== null
}

export const getters = { ...commonGetters }
